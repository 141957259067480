//
// Extended from bootstrap
//
// btn play
.btn-play {
  background-color: var(--#{$prefix}white);
  color: var(--#{$prefix}primary);
  transition: 0.2s ease;
  &:hover {
    background-color: var(--#{$prefix}primary);
    color: var(--#{$prefix}white);
    transition: 0.2s ease;
  }
}

// Sizing
.btn-xs {
  padding: $input-btn-padding-y-xs $input-btn-padding-x-xs;
  font-size: $input-btn-font-size-xs;
}

// btn icon
.btn-icon {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.92969rem;
  font-weight: 400;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
}

.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.53125rem;
  height: 1.53125rem;
}

.btn-icon.btn-sm {
  font-size: 0.875rem;
  width: 2.1875rem;
  height: 2.1875rem;
}

.btn-icon.btn-lg {
  font-size: 1rem;
  width: 3.36875rem;
  height: 3.36875rem;
}

// onclick dropdown active btn icon
.dropdown.active.show .btn-icon {
  background-color: $light-primary;
  color: var(--#{$prefix}primary) !important;
}

// primary hover
.primary-hover {
  &:hover {
    background-color: var(--#{$prefix}primary);
    color: var(--#{$prefix}white);
    border: var(--#{$prefix}primary);
  }
  &:focus {
    background-color: var(--#{$prefix}primary);
    color: var(--#{$prefix}white);
    border: var(--#{$prefix}primary);
    box-shadow: 0 0 0 0.2rem rgba(129, 97, 244, 0.5);
  }
  &.active {
    background-color: var(--#{$prefix}primary);
    color: var(--#{$prefix}white);
    border: var(--#{$prefix}primary);
  }
}

// btn focus none
.btn.btn-focus-none {
  &:focus {
    box-shadow: none;
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-semi-bold;
  color: $btn-link-color;
  text-decoration: underline;

  &:hover {
    color: $btn-link-hover-color;
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}

// btn-dashed

.btn-dashed {
  border-style: dashed !important;

  &:hover {
    border-color: var(--#{$prefix}primary) !important;
    color: var(--#{$prefix}primary) !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

// btn ghost
.btn-ghost {
  --geeks-btn-font-weight: 600;
  --geeks-btn-color: var(--#{$prefix}gray-400);
  --geeks-btn-bg: transparent;
  --geeks-btn-border-color: transparent;
  --geeks-btn-border-radius: 0.5rem;
  --geeks-btn-hover-color: var(--#{$prefix}gray-600);
  --geeks-btn-hover-bg: var(--#{$prefix}btn-ghost-bg);
  --geeks-btn-hover-border-color: var(--#{$prefix}btn-ghost-bg);
  --geeks-btn-focus-shadow-rgb: var(--bd-gray-300-rgb);
  --geeks-btn-active-color: var(--#{$prefix}btn-hover-color);
  --geeks-btn-active-bg: var(--#{$prefix}btn-ghost-bg);
  --geeks-btn-active-border-color: var(--#{$prefix}btn-ghost-bg);
}

// checkbox icon button for ecommerce
.icon-checked {
  opacity: 0;
}

.btn-check:checked + .btn,
.btn-check:active + .btn,
.btn:active,
.btn.active,
.btn.show {
  .icon-checked {
    opacity: 1;
  }
}

.theme-icon-active {
  line-height: 1.5;
}

// Progress bottom to top

.btn-scroll-top {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  display: block;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0.1rem rgba(227, 229, 233, 0.25);
  z-index: 1010;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0.75rem);
  transition:
    all 0.2s linear,
    margin-right 0s;
  background-color: var(--#{$prefix}$white);
  &:after {
    position: absolute;
    font-family: "bootstrap-icons" !important;
    content: "\F145";
    text-align: center;
    line-height: 3rem;
    font-size: 1.5rem;
    color: $primary;
    left: 0;
    top: 0;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
    display: block;
    z-index: 1;
    transition: all 0.2s linear;
  }
  &.active-progress {
    visibility: visible;
    transform: translateY(0);
    @media (min-width: 320px) {
      opacity: 1;
    }
  }
  & svg path {
    fill: none;
  }
  & svg.progress-square path {
    stroke: $primary;
    stroke-width: 2;
    box-sizing: border-box;
    transition: all 0.2s linear;
  }
}

//button
.btn-outline-white {
  --geeks-btn-color: #64748b;
  --geeks-btn-border-color: #cbd5e1;
  --geeks-btn-hover-color: #1e293b;
  --geeks-btn-hover-bg: #e2e8f0;
  --geeks-btn-hover-border-color: #e2e8f0;
  --geeks-btn-focus-shadow-rgb: 51, 58, 74;
  --geeks-btn-active-color: #1e293b;
  --geeks-btn-active-bg: #e2e8f0;
  --geeks-btn-active-border-color: #e2e8f0;
  --geeks-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --geeks-btn-disabled-color: #192e4d;
  --geeks-btn-disabled-bg: transparent;
  --geeks-btn-disabled-border-color: #e2e8f0;
  --geeks-gradient: none;
}

.btn-tag {
  --geeks-btn-color: #64748b;
  --geeks-btn-border-color: #e2e8f0;
  --geeks-btn-hover-color: #1e293b;
  --geeks-btn-hover-bg: #e2e8f0;
  --geeks-btn-hover-border-color: #e2e8f0;
  --geeks-btn-focus-shadow-rgb: 51, 58, 74;
  --geeks-btn-active-color: #1e293b;
  --geeks-btn-active-bg: #e2e8f0;
  --geeks-btn-active-border-color: #e2e8f0;
  --geeks-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --geeks-btn-disabled-color: #192e4d;
  --geeks-btn-disabled-bg: transparent;
  --geeks-btn-disabled-border-color: #e2e8f0;
  --geeks-gradient: none;
  --geeks-btn-border-radius: 50rem;
}
