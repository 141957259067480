// Transitions

.animate-marquee {
  animation: marquee 50s linear infinite !important;
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

@keyframes marquee-left {
  to {
    transform: translateX(0);
  }

  from {
    transform: translateX(-50%);
  }
}
